define("solid-say-editor/services/resource-metadata", ["exports", "@lblod/ember-rdfa-editor/services/resource-metadata", "ember-rdflib"], function (_exports, _resourceMetadata, _emberRdflib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const RDFS = _emberRdflib.default.Namespace("http://www.w3.org/2000/01/rdf-schema#");

  let SolidResourceMetadataService = (_dec = Ember.inject.service("model/store-communicator"), (_class = (_temp = class SolidResourceMetadataService extends _resourceMetadata.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "storeCommunicator", _descriptor, this);
    }

    async fetch(uri) {
      console.log("fetch");

      let resource = _emberRdflib.default.sym(uri);

      let metadata = await this.storeCommunicator.fetchResourceMetaData(resource);
      return {
        label: metadata[RDFS("label").value],
        comment: metadata[RDFS("comment").value]
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "storeCommunicator", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SolidResourceMetadataService;
});
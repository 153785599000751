define("solid-say-editor/services/meta-model-query", ["exports", "@lblod/ember-rdfa-editor-generic-model-plugin-utils/services/meta-model-query"], function (_exports, _metaModelQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _metaModelQuery.default;
    }
  });
});
define("solid-say-editor/services/triples-serialization-utils", ["exports", "@lblod/ember-rdfa-editor-generic-model-plugin-utils/services/triples-serialization-utils"], function (_exports, _triplesSerializationUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _triplesSerializationUtils.default;
    }
  });
});
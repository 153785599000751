define("solid-say-editor/services/rdf-store", ["exports", "solid-addon/services/rdf-store"], function (_exports, _rdfStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rdfStore.default;
    }
  });
});
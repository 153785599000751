define("solid-say-editor/services/rdfa-editor-say-solid-plugin", ["exports", "ember-rdfa-editor-say-solid-plugin/services/rdfa-editor-say-solid-plugin"], function (_exports, _rdfaEditorSaySolidPlugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rdfaEditorSaySolidPlugin.default;
    }
  });
});
define("solid-say-editor/components/editor-container", ["exports", "solid-say-editor/config/editor-document-default-context"], function (_exports, _editorDocumentDefaultContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isReady}}
    {{yield}}
  {{/if}}
  */
  {"id":"Yt4mGMkz","block":"{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"isReady\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"solid-say-editor/components/editor-container.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    isReady: false,

    setRdfaContext(element) {
      element.setAttribute('vocab', JSON.parse(_editorDocumentDefaultContext.default)['vocab']);
      element.setAttribute('prefix', this.prefixToAttrString(JSON.parse(_editorDocumentDefaultContext.default)['prefix']));
      element.setAttribute('typeof', 'foaf:Document');
      element.setAttribute('resource', '#');
    },

    prefixToAttrString(prefix) {
      let attrString = '';
      Object.keys(prefix).forEach(key => {
        let uri = prefix[key];
        attrString += `${key}: ${uri} `;
      });
      return attrString;
    },

    didInsertElement() {
      this._super(...arguments);

      this.setRdfaContext(this.element);
      this.set('isReady', true);
    }

  }));

  _exports.default = _default;
});
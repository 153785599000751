define("solid-say-editor/helpers/getsubjectname", ["exports", "ember-rdfa-editor-say-solid-plugin/helpers/getsubjectname"], function (_exports, _getsubjectname) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _getsubjectname.default;
    }
  });
  Object.defineProperty(_exports, "getSubjectName", {
    enumerable: true,
    get: function () {
      return _getsubjectname.getSubjectName;
    }
  });
});
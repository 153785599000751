define("solid-say-editor/utils/forking-store", ["exports", "solid-addon/utils/forking-store"], function (_exports, _forkingStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _forkingStore.default;
    }
  });
  Object.defineProperty(_exports, "addGraphFor", {
    enumerable: true,
    get: function () {
      return _forkingStore.addGraphFor;
    }
  });
  Object.defineProperty(_exports, "delGraphFor", {
    enumerable: true,
    get: function () {
      return _forkingStore.delGraphFor;
    }
  });
});
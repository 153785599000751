define("solid-say-editor/utils/graphs", ["exports", "solid-addon/utils/graphs"], function (_exports, _graphs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "FORM_GRAPH", {
    enumerable: true,
    get: function () {
      return _graphs.FORM_GRAPH;
    }
  });
  Object.defineProperty(_exports, "SOURCE_GRAPH", {
    enumerable: true,
    get: function () {
      return _graphs.SOURCE_GRAPH;
    }
  });
  Object.defineProperty(_exports, "SOURCE_NODE", {
    enumerable: true,
    get: function () {
      return _graphs.SOURCE_NODE;
    }
  });
  Object.defineProperty(_exports, "META_GRAPH", {
    enumerable: true,
    get: function () {
      return _graphs.META_GRAPH;
    }
  });
});
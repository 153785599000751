define("solid-say-editor/utils/namespaces", ["exports", "solid-addon/utils/namespaces"], function (_exports, _namespaces) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "RDF", {
    enumerable: true,
    get: function () {
      return _namespaces.RDF;
    }
  });
  Object.defineProperty(_exports, "FORM", {
    enumerable: true,
    get: function () {
      return _namespaces.FORM;
    }
  });
  Object.defineProperty(_exports, "SHACL", {
    enumerable: true,
    get: function () {
      return _namespaces.SHACL;
    }
  });
  Object.defineProperty(_exports, "SKOS", {
    enumerable: true,
    get: function () {
      return _namespaces.SKOS;
    }
  });
  Object.defineProperty(_exports, "XSD", {
    enumerable: true,
    get: function () {
      return _namespaces.XSD;
    }
  });
  Object.defineProperty(_exports, "VCARD", {
    enumerable: true,
    get: function () {
      return _namespaces.VCARD;
    }
  });
  Object.defineProperty(_exports, "FOAF", {
    enumerable: true,
    get: function () {
      return _namespaces.FOAF;
    }
  });
  Object.defineProperty(_exports, "LDP", {
    enumerable: true,
    get: function () {
      return _namespaces.LDP;
    }
  });
  Object.defineProperty(_exports, "SP", {
    enumerable: true,
    get: function () {
      return _namespaces.SP;
    }
  });
  Object.defineProperty(_exports, "SOLID", {
    enumerable: true,
    get: function () {
      return _namespaces.SOLID;
    }
  });
  Object.defineProperty(_exports, "DCT", {
    enumerable: true,
    get: function () {
      return _namespaces.DCT;
    }
  });
  Object.defineProperty(_exports, "TRACKER", {
    enumerable: true,
    get: function () {
      return _namespaces.TRACKER;
    }
  });
});
define("solid-say-editor/components/say-solid-footer", ["exports", "ember-rdfa-editor-say-solid-plugin/components/say-solid-footer"], function (_exports, _saySolidFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _saySolidFooter.default;
    }
  });
});
define("solid-say-editor/controllers/editor/new-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editor: null,
    actions: {
      handleRdfaEditorInit(editor) {
        if (editor) this.set('editor', editor);
      }

    }
  });

  _exports.default = _default;
});
define("solid-say-editor/utils/ce/handlers/ignore-modifiers-handler", ["exports", "@lblod/ember-rdfa-editor/utils/ce/handlers/ignore-modifiers-handler"], function (_exports, _ignoreModifiersHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ignoreModifiersHandler.default;
    }
  });
});
define("solid-say-editor/utils/ce/handlers/header-markdown-handler", ["exports", "@lblod/ember-rdfa-editor/utils/ce/handlers/header-markdown-handler"], function (_exports, _headerMarkdownHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _headerMarkdownHandler.default;
    }
  });
});
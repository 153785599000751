define("solid-say-editor/helpers/isequal", ["exports", "ember-rdfa-editor-say-solid-plugin/helpers/isequal"], function (_exports, _isequal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isequal.default;
    }
  });
});
define("solid-say-editor/services/solid-auth", ["exports", "solid-addon/services/solid-auth"], function (_exports, _solidAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _solidAuth.default;
    }
  });
});
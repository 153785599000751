define("solid-say-editor/utils/plugin-hints-registry-api", ["exports", "@lblod/ember-rdfa-editor/utils/plugin-hints-registry-api"], function (_exports, _pluginHintsRegistryApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pluginHintsRegistryApi.default;
    }
  });
});
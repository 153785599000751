define("solid-say-editor/models/semantic-model", ["exports", "solid-addon/models/semantic-model", "solid-addon/components/models/semantic-model"], function (_exports, _semanticModel, _semanticModel2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _semanticModel.default;
    }
  });
  Object.defineProperty(_exports, "rdfType", {
    enumerable: true,
    get: function () {
      return _semanticModel.rdfType;
    }
  });
  Object.defineProperty(_exports, "defaultGraph", {
    enumerable: true,
    get: function () {
      return _semanticModel.defaultGraph;
    }
  });
  Object.defineProperty(_exports, "autosave", {
    enumerable: true,
    get: function () {
      return _semanticModel.autosave;
    }
  });
  Object.defineProperty(_exports, "property", {
    enumerable: true,
    get: function () {
      return _semanticModel2.property;
    }
  });
  Object.defineProperty(_exports, "string", {
    enumerable: true,
    get: function () {
      return _semanticModel2.string;
    }
  });
  Object.defineProperty(_exports, "integer", {
    enumerable: true,
    get: function () {
      return _semanticModel2.integer;
    }
  });
  Object.defineProperty(_exports, "dateTime", {
    enumerable: true,
    get: function () {
      return _semanticModel2.dateTime;
    }
  });
  Object.defineProperty(_exports, "hasMany", {
    enumerable: true,
    get: function () {
      return _semanticModel2.hasMany;
    }
  });
  Object.defineProperty(_exports, "belongsTo", {
    enumerable: true,
    get: function () {
      return _semanticModel2.belongsTo;
    }
  });
  Object.defineProperty(_exports, "term", {
    enumerable: true,
    get: function () {
      return _semanticModel2.term;
    }
  });
  Object.defineProperty(_exports, "solid", {
    enumerable: true,
    get: function () {
      return _semanticModel2.solid;
    }
  });
});
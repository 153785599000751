define("solid-say-editor/components/loading-wrapper", ["exports", "ember-rdfa-editor-say-solid-plugin/components/loading-wrapper"], function (_exports, _loadingWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _loadingWrapper.default;
    }
  });
});
define("solid-say-editor/config/editor-document-default-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultContext = {
    vocab: 'http://data.vlaanderen.be/ns/besluit#',
    prefix: {
      eli: 'http://data.europa.eu/eli/ontology#',
      prov: 'http://www.w3.org/ns/prov#',
      mandaat: 'http://data.vlaanderen.be/ns/mandaat#',
      besluit: 'http://data.vlaanderen.be/ns/besluit#',
      ext: 'http://mu.semte.ch/vocabularies/ext/',
      person: 'http://www.w3.org/ns/person#',
      persoon: 'http://data.vlaanderen.be/ns/persoon#' // Default prefixes will evolve over time, depending on the plugins you install.
      // You need to update this file manually.

    }
  };

  var _default = JSON.stringify(defaultContext);

  _exports.default = _default;
});
define("solid-say-editor/helpers/getlabel", ["exports", "ember-rdfa-editor-say-solid-plugin/helpers/getlabel"], function (_exports, _getlabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _getlabel.default;
    }
  });
  Object.defineProperty(_exports, "getLabel", {
    enumerable: true,
    get: function () {
      return _getlabel.getLabel;
    }
  });
});
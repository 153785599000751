define("solid-say-editor/utils/array-helpers", ["exports", "solid-addon/utils/array-helpers"], function (_exports, _arrayHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "updateEmberArray", {
    enumerable: true,
    get: function () {
      return _arrayHelpers.updateEmberArray;
    }
  });
});
define("solid-say-editor/components/wu-checkbox", ["exports", "@lblod/ember-vo-webuniversum/components/wu-checkbox"], function (_exports, _wuCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _wuCheckbox.default;
    }
  });
});
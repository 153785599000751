define("solid-say-editor/routes/editor/new-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NewDocumentRoute extends Ember.Route {
    model() {
      return `<h1>Solid Say Editor<h1>`;
    }

  }

  _exports.default = NewDocumentRoute;
});
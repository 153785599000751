define("solid-say-editor/templates/editor/new-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G8y1kHIj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"class\",\"profile\",\"value\",\"rdfaEditorInit\",\"editorOptions\",\"toolbarOptions\"],[\"rdfa-editor\",\"all\",[35,2],[30,[36,1],[[32,0],\"handleRdfaEditorInit\"],null],[30,[36,0],null,[[\"showToggleRdfaAnnotations\",\"showInsertButton\",\"showRdfa\",\"showRdfaHighlight\",\"showRdfaHover\"],[\"true\",\"true\",\"true\",\"true\",\"true\"]]],[30,[36,0],null,[[\"showTextStyleButtons\",\"showListButtons\",\"showIndentButtons\"],[\"true\",\"true\",\"true\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"action\",\"model\",\"rdfa/rdfa-editor\",\"editor-container\"]}",
    "meta": {
      "moduleName": "solid-say-editor/templates/editor/new-document.hbs"
    }
  });

  _exports.default = _default;
});
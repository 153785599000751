define("solid-say-editor/components/editor-plugins/date-card", ["exports", "@lblod/ember-rdfa-editor-date-plugin/components/editor-plugins/date-card"], function (_exports, _dateCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dateCard.default;
    }
  });
});
define("solid-say-editor/models/rdfs-property", ["exports", "@lblod/ember-rdfa-editor-generic-model-plugin-utils/models/rdfs-property"], function (_exports, _rdfsProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rdfsProperty.default;
    }
  });
});
define("solid-say-editor/utils/ce/capped-history", ["exports", "@lblod/ember-rdfa-editor/utils/ce/capped-history"], function (_exports, _cappedHistory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cappedHistory.default;
    }
  });
});
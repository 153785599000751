define("solid-say-editor/components/rdfa/editor-toolbar", ["exports", "@lblod/ember-rdfa-editor/components/rdfa/editor-toolbar"], function (_exports, _editorToolbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _editorToolbar.default;
    }
  });
});
define("solid-say-editor/components/rdfa/rdfa-context-debugger", ["exports", "@lblod/ember-rdfa-editor/components/rdfa/rdfa-context-debugger"], function (_exports, _rdfaContextDebugger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rdfaContextDebugger.default;
    }
  });
});
define("solid-say-editor/components/profile-header", ["exports", "ember-rdfa-editor-say-solid-plugin/components/profile-header"], function (_exports, _profileHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _profileHeader.default;
    }
  });
});
define("solid-say-editor/components/editor-plugins/say-solid-save-reset-card", ["exports", "ember-rdfa-editor-say-solid-plugin/components/editor-plugins/say-solid-save-reset-card"], function (_exports, _saySolidSaveResetCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _saySolidSaveResetCard.default;
    }
  });
});
define("solid-say-editor/components/modals/forms/subject-form", ["exports", "ember-rdfa-editor-say-solid-plugin/components/modals/forms/subject-form"], function (_exports, _subjectForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _subjectForm.default;
    }
  });
});
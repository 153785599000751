define("solid-say-editor/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends Ember.Route {
    activate() {
      this.transitionTo("editor/new-document");
    }

  }

  _exports.default = IndexRoute;
});
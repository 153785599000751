define("solid-say-editor/helpers/rdfa/is-uri", ["exports", "@lblod/ember-rdfa-editor/helpers/rdfa/is-uri"], function (_exports, _isUri) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isUri.default;
    }
  });
  Object.defineProperty(_exports, "isUri", {
    enumerable: true,
    get: function () {
      return _isUri.isUri;
    }
  });
});
define("solid-say-editor/services/rdfa-editor-dispatcher", ["exports", "solid-say-editor/config/editor-profiles"], function (_exports, _editorProfiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Return all plugins mentioned in any supplied profile
  function calculateAllPlugins(profiles) {
    let profileArrays = [];

    for (var key in profiles) profileArrays = [...profileArrays, ...profiles[key]];

    return Ember.A(profileArrays).uniq();
  } // Build a variable name for a given plugin name
  // This name is unique in the class


  function variableNameForPlugin(pluginName) {
    return "_p_" + Ember.String.camelize(pluginName);
  } // Standard body of our service


  let service = {
    /**
    * Dispatch an event to all plugins of a given profile
    *
    * @method dispatch
    *
    * @param {string} profile Editor profile
    * @param {Object} hintsRegistryIndex Unique identifier of the event in the hints registry
    * @param {Array} contexts RDFa contexts of the text snippets the event applies on
    * @param {Object} hintsRegistry Registry of hints in the editor
    * @param {Object} editor The RDFa editor instance
    * @param {Array} Optional argument to contain extra info.
    *
    * @return {Promise} A promise that resolves when the event has been dispatched to the plugins
    *
    */
    dispatch(profile, hintsRegistryIndex, contexts, hintsRegistry, editor, extraInfo = []) {
      let self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        const plugins = _editorProfiles.default[profile];

        if (plugins) {
          plugins.forEach(plugin => {
            const pluginService = self.get(variableNameForPlugin(plugin));

            if (typeof pluginService.get('execute.perform') == 'function') {
              // ember-concurrency task
              Ember.run.next(() => {
                pluginService.get('execute').perform(hintsRegistryIndex, contexts, hintsRegistry, editor, extraInfo);
              });
            } else if (typeof pluginService.get('execute') == 'function') {
              pluginService.execute(hintsRegistryIndex, contexts, hintsRegistry, editor, extraInfo);
            } else {
              (true && Ember.warn(`Plugin ${plugin} doesn't provide 'execute' as function nor ember-concurrency task`, {
                id: "disptacher_plugin_no_execute"
              }));
            }
          });
        } else {
          (true && Ember.warn(`Editor plugin profile "${profile}" was not found`, {
            id: "disptacher_no_profile"
          }));
        }

        resolve();
      });
    },

    async requestHints(profile, context, editor) {
      const plugins = _editorProfiles.default[profile];
      const hints = Ember.A();

      if (plugins) {
        for (let plugin of plugins) {
          let pluginService = this.get(variableNameForPlugin(plugin));

          if (pluginService.suggestHints) {
            let receivedHints = await pluginService.suggestHints(context, editor);
            hints.pushObjects(receivedHints);
          }
        }
      }

      return hints;
    },

    init() {
      this._super(...arguments);

      let pluginServices = Ember.A();
      let allPlugins = calculateAllPlugins(_editorProfiles.default);
      allPlugins.forEach(p => pluginServices.pushObject(this.get(variableNameForPlugin(p))));
      this.set('pluginServices', pluginServices);
    }

  }; // Add the plugin's services to this service

  let allPlugins = calculateAllPlugins(_editorProfiles.default);
  allPlugins.forEach(p => {
    service[variableNameForPlugin(p)] = Ember.inject.service(Ember.String.dasherize(p));
  });
  /**
  * RDFa Editor system dispatcher that dispatches editor events to the configured plugins
  *
  * @module editor-core
  * @class RdfaEditorDispatcher
  * @extends Service
  */

  var _default = Ember.Service.extend(service);

  _exports.default = _default;
});
define("solid-say-editor/components/selectors/type-selector", ["exports", "ember-rdfa-editor-say-solid-plugin/components/selectors/type-selector"], function (_exports, _typeSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _typeSelector.default;
    }
  });
});
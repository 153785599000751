define("solid-say-editor/utils/ce/handlers/undo-handler", ["exports", "@lblod/ember-rdfa-editor/utils/ce/handlers/undo-input-handler"], function (_exports, _undoInputHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _undoInputHandler.default;
    }
  });
});